<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/company/AddCompany"
      finish-router="company"
      permission="Com_Create"
    />
  </page-content>
</template>

<script>
import fields from "@/views/company/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import common from "@/common";


export default {
  name: "Add",
  components: {
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('company') + ' - ' + common.getI18n('common.add'),
      fields,
      row_data: {}
    }
  }
}
</script>

<style scoped>

</style>
